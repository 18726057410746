* {
  --white: #ffffff !important;
  --primary-color: #CC3000 !important;
  --secondary-color: #801E00 !important;
  --thirty-color: #f5f5f5 !important;
  --fourth-color: #d8d8d8 !important;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

.tableCustom th {
  background-color: #343434;
  border: 0;
}

.camera-animation {
  animation-duration: 0.6s;
  animation-name: animation-camera;
}

@keyframes animation-camera {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(0.8);
      opacity: 0.5;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}